//import axios from 'axios'
//let axiosInstance = axios.create();  // 建立實體
// common
import Dialogue from "@/components/common/dialogue_client.vue"
// components
const Sidebar = resolve => require(["@/components/admin/school_buying/_sidebar.vue"], resolve)
const TableList = resolve => require(["@/components/admin/school_buying/list/_table_list.vue"], resolve)
const SchoolInfo = resolve => require(["@/components/admin/school_buying/common/_school_info.vue"], resolve)

export default {
    components: {
        Dialogue,
        Sidebar,
        TableList,
        SchoolInfo
    },
    data: function() {
        return {
            activePage: null,

            allSchoolBuyingIds: [],
            searchText: '',
            renderTableListKey: 0,

            // 業務清單
            salesRepList: [],

            isGettingApi: {
                downloadFile: false
            }
        }
    },
    computed: {
        sidebarList() {
            let list = [];

            list = [
                {
                    name: '全部訂單',
                    state: 'all',
                    url: '/admin/school-buying/list/all',
                    isShow: true
                }
            ]

            list.forEach(el => {
                el.isActive = el.url == this.$route.path;
            });
            list = list.filter(item => item.isShow);

            return list;
        },
        isClickSidebarLink: {
            get() {
                return this.$store.state.adminSchoolBuying.isClickSidebarLink;
            },
            set(value) {
                this.$store.commit('adminSchoolBuying/setIsClickSidebarLink', value);
            }
        }
    },
    created: function() {
        this.isClickSidebarLink = false;
        this.getSchoolBuyingIdsByState();
        this.getCountyDistrict();
    },
    methods: {
        getActivePage() {
            return new Promise((resolve, reject) => {
                this.activePage = this.sidebarList.find(el => el.isActive);
                if (this.activePage) {
                    resolve();
                } else {
                    reject("admin/school_buying/_list.js: 'getActivePage' get error");
                }
            });
        },
        async getSchoolBuyingIdsByState() {
            await this.getActivePage();

            this.allSchoolBuyingIds = [];
            this.searchText = '';

            this.$httpRequest.get('/admin_api/order/get_school_order_ids')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allSchoolBuyingIds = result.ids;
                            this.renderTableListKey++;
                        }
                        this.salesRepList = result.sales_rep;
                    }
                })
                .catch(error => {
                    console.error('catched error: ' + error);
                });
        },
        async getCountyDistrict() {
            let innerData = '';
            await $.getJSON('https://resource.learnmode.net/static/county_district.json', function(data) {
                innerData = data;
            });
            this.$store.commit('adminSchoolBuying/setCountyDistrict', innerData);
        },
        getSchoolBuyingIdsBySearchText() {
            let params = {
                    baan: this.searchText,
                    //status: this.$route.params.subPage
                }

            if (!this.searchText) {
                this.getSchoolBuyingIdsByState();
                return;
            }

            this.allSchoolBuyingIds = [];

            this.$httpRequest.get('/admin_api/order/get_school_order_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allSchoolBuyingIds = result.ids;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(function (error) {
                    console.error('catched error: ' + error);
                });
        },
        checkIsNumber(num) { // 檢查是否有數字以外的字元，有的話會直接無法輸入
            return num.replace(/[^0-9]/g, "");
        },
        checkIsWord(val) { // 檢查是否有英文大寫或數字以外的字元，有的話會直接無法輸入
            return val.replace(/[^0-9A-Z]/g, "");
        },
        showAddSchoolBuyingDialogue() {
            $('#schoolBuyingAddingDialogue').modal({
                backdrop: 'static',
                show: true
            });
        },
        downloadReport() {
            this.$httpRequest.post('/admin_api/order/download_school_order_report')
                .then(async response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            try {
                                await this.$util.downloadFile(result.url, result.name);
                                this.isPostingApi.downloadExcel = false;
                            } catch (error) {
                                this.isPostingApi.downloadExcel = false;
                                console.error('Catched Error:', error);
                            }
                        }

                    }
                    else {
                        this.$store.dispatch('common/setAlert', { msg: '資料處理中，請稍後再試', status: 'danger' });
                    }
                })
                .catch(error => {
                    console.error('catched error: ' + error);
                });
        }
    }
}
